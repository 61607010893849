@tailwind base;
@tailwind components;
@tailwind utilities;

*, *::before, *::after {
    box-sizing: inherit;
}

html {
    /* this makes sure the padding and the border is included in the box sizing */
    box-sizing: border-box;
    overflow-x: hidden;
}

html body {
    padding: 0;
    margin: 0;
}

::-webkit-scrollbar {
    width: 12px;
    cursor: auto;
}
  
/* Track */
::-webkit-scrollbar-track {
background: #292b2f;
/* border-radius: 3px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #202225;
/* border-radius: 3px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #1e1e20;
}